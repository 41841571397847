<template>
  <div class="warp">
    <div class="header">
      <div class="title" align="center">
        <div style="font-size: 22px;color: #333333;letter-spacing: 0;font-weight: 600;">{{getScreeningTitle}}</div>
      </div>
      <p style="font-size: 14px;color: #333333;letter-spacing: 0;font-weight: 600;margin-top: 10px">ID:{{screeningData.id}}</p>
    </div>
    <div class="info">
      <el-row type="flex" justify="space-around">
        <el-col :span="6"><div class="grid-content bg-purple">姓名:{{patientInfo.name || '-'}}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">性别:{{patientInfo.sex || '-'}}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">年龄:{{patientInfo.age || '-'}}</div></el-col>
      </el-row>
      <el-row type="flex" justify="space-around" style="margin-top: 10px;">
        <el-col :span="6"><div class="grid-content bg-purple">住院号:{{patientInfo.visitCode || '-'}}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">科室:{{patientInfo.wardDepart || '-'}}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">床号:{{patientInfo.wardNo || '-'}}</div></el-col>
      </el-row>
    </div>

    <div class="content">
      <div class="title" style="margin-bottom: 30px;">一、(以下任一项答“是”，则进入最终筛查；所有回答“否”，说明目前无营养风险，一周后复查)</div>
      <el-form :model="context"
               ref="form1"
               label-position="left"
               label-width="70%"
               style="margin-left: 30px;margin-right: 30px;"
      >
        <el-form-item label="是否BMI<18.5?" prop="bmi" class="item" style="border-top: 1px solid #E4EBF5">
          <el-radio-group v-model="context.bmi">
            <el-radio v-for="(item, index) in formOption.bmiOptions" :key="index" :label="item.value"
                      :disabled="item.disabled" style="{display: inline}">{{item.label}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="患者在过去1~3个月有体重下降吗？" prop="height" class="item">
          <el-radio-group v-model="context.height">
            <el-radio v-for="(item, index) in formOption.heightOptions" :key="index" :label="item.value"
                      :disabled="item.disabled" style="{display: inline}">{{item.label}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="患者在过去的1周内有摄食减少吗？" prop="diet" class="item">
          <el-radio-group v-model="context.diet">
            <el-radio v-for="(item, index) in formOption.dietOptions" :key="index" :label="item.value"
                      :disabled="item.disabled" style="{display: inline}">{{item.label}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="有严重疾病吗（如ICU治疗）？" prop="disease" class="item">
          <el-radio-group v-model="context.disease">
            <el-radio v-for="(item, index) in formOption.diseaseOptions" :key="index" :label="item.value"
                      :disabled="item.disabled" style="{display: inline}">{{item.label}}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="title">二、（疾病状况评分+营养状况评分+年龄评分） 总分： {{screeningData.score}} 分</div>
      <el-form :model="context" ref="form2"   label-position="left"  style="margin-left: 30px;margin-right: 30px;">
        <div class="static-content-item">
          <div class="title" style="margin-top: 15px;margin-bottom: 30px;">疾病严重程度评分 (3.0)</div>
        </div>
        <el-form-item label="1分:"  prop="degreeOne" class="checkbox-item" style="border-top: 1px solid #E4EBF5">
          <el-checkbox-group v-model="context.degreeOne">
            <el-checkbox v-for="(item, index) in formOption.degreeOneOptions" :key="index" :label="item.value"
                         :disabled="item.disabled" style="{display: inline}">{{item.label}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="2分:"  prop="degreeTwo" class="checkbox-item">
          <el-checkbox-group v-model="context.degreeTwo"  >
            <el-checkbox v-for="(item, index) in formOption.degreeTwoOptions" :key="index" :label="item.value"
                         :disabled="item.disabled" style="{display: inline}">{{item.label}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="3分:"  prop="degreeThree" class="checkbox-item">
          <el-checkbox-group v-model="context.degreeThree"  >
            <el-checkbox v-for="(item, index) in formOption.degreeThreeOptions" :key="index" :label="item.value"
                         :disabled="item.disabled" style="{display: inline}">{{item.label}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div class="static-content-item">
          <div class="title" style="color: #fad572;margin-bottom: 10px;margin-top: -10px;">若不符合上述明确诊断者，按以下标准进行疾病严重程度评分:</div>
        </div>
        <el-form-item label="" prop="otherDegree" >
          <el-radio-group v-model="context.otherDegree">
            <el-radio v-for="(item, index) in formOption.otherDegreeOptions" :class="{boder:index==0}" :key="index" :label="item.value" class="radio" style="display: block;width: 100%;padding: 10px">{{item.label}}</el-radio>


          </el-radio-group>
        </el-form-item>
        <div class="static-content-item">
          <div class="title" style="margin-bottom: 20px;">营养受损状况评分 (3分)</div>
        </div>
        <el-form-item label=""  prop="status">
          <el-radio-group v-model="context.status">
            <el-radio v-for="(item, index) in formOption.statusOptions" :key="index"
                      :label="item.value"
                      :class="{boder:index==0}"
                      class="radio" style="display: block;width: 100%;padding: 10px"
                      :disabled="item.disabled">{{item.label}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="static-content-item">
          <div class="title" style="margin-bottom: 20px;">年龄评分 (1分)</div>
        </div>
        <el-form-item label="" prop="age">
          <el-radio-group v-model="context.age">
            <el-radio v-for="(item, index) in formOption.ageOptions" :key="index" :label="item.value"
                      :class="{boder:index==0}" class="radio" style="display: block;width: 100%;padding: 10px"
                      :disabled="item.disabled" >{{item.label}}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="result">
        <div>筛查总分:    {{screeningData.score}}  分</div>
        <div>筛查结果:    {{getScreenRes}}</div>
        <div>计划筛查:</div>
      </div>
      <div class="bottom">
        <div>日期:      {{screeningData.examTime || '-'}}</div>
        <div>筛查人:     {{screeningData.examPerson || '-'}}</div>
      </div>
    </div>
    <div class="mask"></div>
  </div>
</template>

<script>
import formOption from '@/const/patient/nutriScreening.js';
export default {
  name: "nrs",
  props: {
    screeningData: {
      type: Object,
      default: () => {
      },
    },
    context: {
      type: Object,
      default: () => {
      },
    },
    patientInfo: {
      type: Object,
      default: () => {
      },
    },

  },
  data(){
    return{
      formOption
    }
  },
  computed: {
    getScreeningTitle() {
      const formType = this.screeningData.formType;
      return formType == 1 ? '营养风险筛查NRS2002' : "未知";
    },
    //计算筛查结果
    getScreenRes() {
      const score = this.screeningData.score;
      return score <= 2 ? "无营养风险,可每周重新评估其营养状况" : "患者有营养不良的风险，需营养治疗";
    },
  },
};
</script>

<style scoped lang="scss">
.item {
  border-bottom: 1px solid #E4EBF5;
  border-left: 1px solid #E4EBF5;
  border-right: 1px solid #E4EBF5;
  margin-top: -22px;
  padding-left: 20px;
}
.checkbox-item{
  border-bottom: 1px solid #E4EBF5;
  border-left: 1px solid #E4EBF5;
  border-right: 1px solid #E4EBF5;
  margin-top: -22px;
  padding-left: 20px;
}
.radio{
  border-bottom: 1px solid #E4EBF5;
  border-left: 1px solid #E4EBF5;
  border-right: 1px solid #E4EBF5;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  font-weight: 400;
}
.content{
  margin-top: 20px;
  .title{
    font-size: 14px;
    color: #333333;
    letter-spacing: 0;
    font-weight: 600;
  }
}
.warp {
  position: relative;
}
.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  bottom: 0;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 999;
}
.header{
  border-bottom: 2px solid ;
  padding-bottom: 5px;
}
.info{
  padding: 10px 0;
  border-bottom: 2px dotted;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  font-weight: 400;
}
.result{
  padding: 10px 0 10px 30px;
  border-top: 2px dotted;
  border-bottom: 2px dotted;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  font-weight: 600;

  div{
    margin-top: 10px;
  }
}
.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  font-weight: 600;
  margin-left: 30px;
  margin-right: 30px;
}
.boder{
  border-top: 1px solid #E4EBF5;
}
</style>
