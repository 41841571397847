var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "warp" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "title", attrs: { align: "center" } }, [
        _c(
          "div",
          {
            staticStyle: {
              "font-size": "22px",
              color: "#333333",
              "letter-spacing": "0",
              "font-weight": "600",
            },
          },
          [_vm._v(_vm._s(_vm.getScreeningTitle))]
        ),
      ]),
      _c(
        "p",
        {
          staticStyle: {
            "font-size": "14px",
            color: "#333333",
            "letter-spacing": "0",
            "font-weight": "600",
            "margin-top": "10px",
          },
        },
        [_vm._v("ID:" + _vm._s(_vm.screeningData.id))]
      ),
    ]),
    _c(
      "div",
      { staticClass: "info" },
      [
        _c(
          "el-row",
          { attrs: { type: "flex", justify: "space-around" } },
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("姓名:" + _vm._s(_vm.patientInfo.name || "-")),
              ]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("性别:" + _vm._s(_vm.patientInfo.sex || "-")),
              ]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("年龄:" + _vm._s(_vm.patientInfo.age || "-")),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          {
            staticStyle: { "margin-top": "10px" },
            attrs: { type: "flex", justify: "space-around" },
          },
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("住院号:" + _vm._s(_vm.patientInfo.visitCode || "-")),
              ]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("科室:" + _vm._s(_vm.patientInfo.wardDepart || "-")),
              ]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _vm._v("床号:" + _vm._s(_vm.patientInfo.wardNo || "-")),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "div",
          { staticClass: "title", staticStyle: { "margin-bottom": "30px" } },
          [
            _vm._v(
              "一、(以下任一项答“是”，则进入最终筛查；所有回答“否”，说明目前无营养风险，一周后复查)"
            ),
          ]
        ),
        _c(
          "el-form",
          {
            ref: "form1",
            staticStyle: { "margin-left": "30px", "margin-right": "30px" },
            attrs: {
              model: _vm.context,
              "label-position": "left",
              "label-width": "70%",
            },
          },
          [
            _c(
              "el-form-item",
              {
                staticClass: "item",
                staticStyle: { "border-top": "1px solid #E4EBF5" },
                attrs: { label: "是否BMI<18.5?", prop: "bmi" },
              },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.context.bmi,
                      callback: function ($$v) {
                        _vm.$set(_vm.context, "bmi", $$v)
                      },
                      expression: "context.bmi",
                    },
                  },
                  _vm._l(_vm.formOption.bmiOptions, function (item, index) {
                    return _c(
                      "el-radio",
                      {
                        key: index,
                        staticStyle: { "{display": "inline}" },
                        attrs: { label: item.value, disabled: item.disabled },
                      },
                      [_vm._v(_vm._s(item.label))]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "item",
                attrs: {
                  label: "患者在过去1~3个月有体重下降吗？",
                  prop: "height",
                },
              },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.context.height,
                      callback: function ($$v) {
                        _vm.$set(_vm.context, "height", $$v)
                      },
                      expression: "context.height",
                    },
                  },
                  _vm._l(_vm.formOption.heightOptions, function (item, index) {
                    return _c(
                      "el-radio",
                      {
                        key: index,
                        staticStyle: { "{display": "inline}" },
                        attrs: { label: item.value, disabled: item.disabled },
                      },
                      [_vm._v(_vm._s(item.label))]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "item",
                attrs: {
                  label: "患者在过去的1周内有摄食减少吗？",
                  prop: "diet",
                },
              },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.context.diet,
                      callback: function ($$v) {
                        _vm.$set(_vm.context, "diet", $$v)
                      },
                      expression: "context.diet",
                    },
                  },
                  _vm._l(_vm.formOption.dietOptions, function (item, index) {
                    return _c(
                      "el-radio",
                      {
                        key: index,
                        staticStyle: { "{display": "inline}" },
                        attrs: { label: item.value, disabled: item.disabled },
                      },
                      [_vm._v(_vm._s(item.label))]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "item",
                attrs: {
                  label: "有严重疾病吗（如ICU治疗）？",
                  prop: "disease",
                },
              },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.context.disease,
                      callback: function ($$v) {
                        _vm.$set(_vm.context, "disease", $$v)
                      },
                      expression: "context.disease",
                    },
                  },
                  _vm._l(_vm.formOption.diseaseOptions, function (item, index) {
                    return _c(
                      "el-radio",
                      {
                        key: index,
                        staticStyle: { "{display": "inline}" },
                        attrs: { label: item.value, disabled: item.disabled },
                      },
                      [_vm._v(_vm._s(item.label))]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "title" }, [
          _vm._v(
            "二、（疾病状况评分+营养状况评分+年龄评分） 总分： " +
              _vm._s(_vm.screeningData.score) +
              " 分"
          ),
        ]),
        _c(
          "el-form",
          {
            ref: "form2",
            staticStyle: { "margin-left": "30px", "margin-right": "30px" },
            attrs: { model: _vm.context, "label-position": "left" },
          },
          [
            _c("div", { staticClass: "static-content-item" }, [
              _c(
                "div",
                {
                  staticClass: "title",
                  staticStyle: {
                    "margin-top": "15px",
                    "margin-bottom": "30px",
                  },
                },
                [_vm._v("疾病严重程度评分 (3.0)")]
              ),
            ]),
            _c(
              "el-form-item",
              {
                staticClass: "checkbox-item",
                staticStyle: { "border-top": "1px solid #E4EBF5" },
                attrs: { label: "1分:", prop: "degreeOne" },
              },
              [
                _c(
                  "el-checkbox-group",
                  {
                    model: {
                      value: _vm.context.degreeOne,
                      callback: function ($$v) {
                        _vm.$set(_vm.context, "degreeOne", $$v)
                      },
                      expression: "context.degreeOne",
                    },
                  },
                  _vm._l(
                    _vm.formOption.degreeOneOptions,
                    function (item, index) {
                      return _c(
                        "el-checkbox",
                        {
                          key: index,
                          staticStyle: { "{display": "inline}" },
                          attrs: { label: item.value, disabled: item.disabled },
                        },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }
                  ),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "checkbox-item",
                attrs: { label: "2分:", prop: "degreeTwo" },
              },
              [
                _c(
                  "el-checkbox-group",
                  {
                    model: {
                      value: _vm.context.degreeTwo,
                      callback: function ($$v) {
                        _vm.$set(_vm.context, "degreeTwo", $$v)
                      },
                      expression: "context.degreeTwo",
                    },
                  },
                  _vm._l(
                    _vm.formOption.degreeTwoOptions,
                    function (item, index) {
                      return _c(
                        "el-checkbox",
                        {
                          key: index,
                          staticStyle: { "{display": "inline}" },
                          attrs: { label: item.value, disabled: item.disabled },
                        },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }
                  ),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "checkbox-item",
                attrs: { label: "3分:", prop: "degreeThree" },
              },
              [
                _c(
                  "el-checkbox-group",
                  {
                    model: {
                      value: _vm.context.degreeThree,
                      callback: function ($$v) {
                        _vm.$set(_vm.context, "degreeThree", $$v)
                      },
                      expression: "context.degreeThree",
                    },
                  },
                  _vm._l(
                    _vm.formOption.degreeThreeOptions,
                    function (item, index) {
                      return _c(
                        "el-checkbox",
                        {
                          key: index,
                          staticStyle: { "{display": "inline}" },
                          attrs: { label: item.value, disabled: item.disabled },
                        },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }
                  ),
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "static-content-item" }, [
              _c(
                "div",
                {
                  staticClass: "title",
                  staticStyle: {
                    color: "#fad572",
                    "margin-bottom": "10px",
                    "margin-top": "-10px",
                  },
                },
                [
                  _vm._v(
                    "若不符合上述明确诊断者，按以下标准进行疾病严重程度评分:"
                  ),
                ]
              ),
            ]),
            _c(
              "el-form-item",
              { attrs: { label: "", prop: "otherDegree" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.context.otherDegree,
                      callback: function ($$v) {
                        _vm.$set(_vm.context, "otherDegree", $$v)
                      },
                      expression: "context.otherDegree",
                    },
                  },
                  _vm._l(
                    _vm.formOption.otherDegreeOptions,
                    function (item, index) {
                      return _c(
                        "el-radio",
                        {
                          key: index,
                          staticClass: "radio",
                          class: { boder: index == 0 },
                          staticStyle: {
                            display: "block",
                            width: "100%",
                            padding: "10px",
                          },
                          attrs: { label: item.value },
                        },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }
                  ),
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "static-content-item" }, [
              _c(
                "div",
                {
                  staticClass: "title",
                  staticStyle: { "margin-bottom": "20px" },
                },
                [_vm._v("营养受损状况评分 (3分)")]
              ),
            ]),
            _c(
              "el-form-item",
              { attrs: { label: "", prop: "status" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.context.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.context, "status", $$v)
                      },
                      expression: "context.status",
                    },
                  },
                  _vm._l(_vm.formOption.statusOptions, function (item, index) {
                    return _c(
                      "el-radio",
                      {
                        key: index,
                        staticClass: "radio",
                        class: { boder: index == 0 },
                        staticStyle: {
                          display: "block",
                          width: "100%",
                          padding: "10px",
                        },
                        attrs: { label: item.value, disabled: item.disabled },
                      },
                      [_vm._v(_vm._s(item.label))]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "static-content-item" }, [
              _c(
                "div",
                {
                  staticClass: "title",
                  staticStyle: { "margin-bottom": "20px" },
                },
                [_vm._v("年龄评分 (1分)")]
              ),
            ]),
            _c(
              "el-form-item",
              { attrs: { label: "", prop: "age" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.context.age,
                      callback: function ($$v) {
                        _vm.$set(_vm.context, "age", $$v)
                      },
                      expression: "context.age",
                    },
                  },
                  _vm._l(_vm.formOption.ageOptions, function (item, index) {
                    return _c(
                      "el-radio",
                      {
                        key: index,
                        staticClass: "radio",
                        class: { boder: index == 0 },
                        staticStyle: {
                          display: "block",
                          width: "100%",
                          padding: "10px",
                        },
                        attrs: { label: item.value, disabled: item.disabled },
                      },
                      [_vm._v(_vm._s(item.label))]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "result" }, [
          _c("div", [
            _vm._v("筛查总分:    " + _vm._s(_vm.screeningData.score) + "  分"),
          ]),
          _c("div", [_vm._v("筛查结果:    " + _vm._s(_vm.getScreenRes))]),
          _c("div", [_vm._v("计划筛查:")]),
        ]),
        _c("div", { staticClass: "bottom" }, [
          _c("div", [
            _vm._v("日期:      " + _vm._s(_vm.screeningData.examTime || "-")),
          ]),
          _c("div", [
            _vm._v(
              "筛查人:     " + _vm._s(_vm.screeningData.examPerson || "-")
            ),
          ]),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "mask" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }