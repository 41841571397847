export default {
  bmiOptions: [{
    "label": "是",
    "value": 1
  }, {
    "label": "否",
    "value": 0
  }],
  heightOptions: [{
    "label": "是",
    "value": 1
  }, {
    "label": "否",
    "value": 0
  }],
  dietOptions: [{
    "label": "是",
    "value": 1
  }, {
    "value": 0,
    "label": "否"
  }],
  diseaseOptions: [{
    "label": "是",
    "value": 1
  }, {
    "label": "否",
    "value": 0
  }],
  degreeOneOptions: [{
    "label": "一般恶性肿瘤患者",
    "value": 1
  }, {
    "label": "髋部骨折",
    "value": 2
  }, {
    "label": "长期血液透析",
    "value": 3
  }, {
    "value": 4,
    "label": "糖尿病"
  }, {
    "value": 5,
    "label": "慢性疾病(如肝硬化，COPD)"
  }],
  degreeTwoOptions: [{
    "label": "血液恶性肿瘤",
    "value": 1
  }, {
    "label": "重度肺炎",
    "value": 2
  }, {
    "label": "腹部大手术",
    "value": 3
  }, {
    "value": 4,
    "label": "脑卒中"
  }],
  degreeThreeOptions: [{
    "label": "颅脑损伤",
    "value": 1
  }, {
    "label": "骨髓移植",
    "value": 2
  }, {
    "label": "重症监护患者(APACHE>10)",
    "value": 3
  }],
  otherDegreeOptions: [{
    "label": "慢性疾病患者因出现并发症而住院治疗。患者虚弱但不需卧床。蛋白质需要量略有增加，但可以通过口服和补充来弥补",
    "value": 1
  }, {
    "value": 2,
    "label": "卧床患者,如大手术后。蛋白质需要量相应增加,但大多数仍可以通过人工营养得到恢复"
  }, {
    "value": 3,
    "label": "患者在加病房中靠机械通气支持。蛋白质需要量增加而且人工营养支持不足以弥补,但是通过适当的人工营养可以使蛋白质分解和氮丢失明显减少"
  }],
  statusOptions: [{
    "label": "正常营养状态,BMI≥18.5,近1~3月体重无变化,近1周摄食量无变化",
    "value": 1
  }, {
    "label": "3个月内体重丢失>5%,或食物摄入比正常需要量低25%~50%",
    "value": 2
  }, {
    "label": "一般情况差,或2个月内体重丢失>5%,或食物摄入比正常需要量低50%~75%",
    "value": 3
  }, {
    "value": 4,
    "label": "BMI<18.5且般情况差,或1个月内体重丢失>5%,或3个月内体重丢失>15%,或前1周食物摄入比正常需要低75%~100%"
  }],
  ageOptions: [{
    "label": "年龄<70岁",
    "value": 1
  }, {
    "label": "年龄≥70岁",
    "value": 2
  }],
};
